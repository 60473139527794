<template>
  <div class="scrolling-container">
    <vuescroll :ops="ops">
      <div class="layers-products__products">
        <div class="layers-products__products-item" v-for="product in products" :key="product.id">
          <div class="mobile-trigger" @click="selectProduct(product)"></div>
          <div class="image">
            <img :src="product.image" :alt="product.name" />
            <div class="controls">
              <SimpleButton
                :color="'default'"
                :reverse="'reverse'"
                @clickedFromSimpleBtn="selectProduct(product)"
              >
                <template #icon>
                  <img src="@/assets/img/icons/modal--products-check.svg" alt="" class="icon" />
                </template>
                {{ $t('message.productsModal.chose') }}
              </SimpleButton>
              <tippy
                v-if="isShowTooltip(product)"
                interactive
                placement="right-start"
                :maxWidth="308"
              >
                <template #trigger>
                  <div class="controls-info">
                    <img src="@/assets/img/icons/product--info.svg" alt="i" />
                  </div>
                </template>
                <span class="product-info__tooltip">
                  <span class="product-info__tooltip-text" v-if="product.text !== ''">
                    {{ product.text }}
                  </span>
                  <a
                    :href="product.url"
                    target="_blank"
                    class="product-info__tooltip-link"
                    v-if="product.url !== ''"
                    :class="{ mt: product.text !== '' }"
                  >
                    <img src="@/assets/img/icons/planet.svg" alt="" />
                    {{ $t('message.productsModal.more') }}
                  </a>
                </span>
              </tippy>
            </div>
          </div>
          <div class="name">
            {{ product.name }}
          </div>
        </div>
      </div>
    </vuescroll>
  </div>
</template>

<script>
import vuescroll from 'vuescroll'
import { mapActions, mapMutations, mapState } from 'vuex'
import { cloneDeep } from 'lodash'
import SimpleButton from '@/components/elements/Dom/Simple-button.vue'

export default {
  components: {
    SimpleButton,
    vuescroll
  },
  props: {
    products: Array,
    layerIndex: Number,
    itemIndex: Number,
    placement: String,
    slopeType: String,
    action: String,
    fastenerIndex: Number,
    isMultilayerItem: Boolean
  },
  data: () => ({
    ops: {
      rail: {
        opacity: 1,
        background: '#ECECEC',
        size: '4px'
      },
      bar: {
        background: 'rgba(234,69,78,0.25)',
        keepShow: true,
        size: '4px',
        minSize: 0.2
      }
    }
  }),
  methods: {
    ...mapMutations({
      UPDATE_SECTOR_SYSTEM_LAYER_ITEM_MATERIAL: 'UPDATE_SECTOR_SYSTEM_LAYER_ITEM_MATERIAL',
      UPDATE_JUNCTION_LAYER_ITEM_MATERIAL: 'UPDATE_JUNCTION_LAYER_ITEM_MATERIAL',
      UPDATE_SECTOR_WEDGE_MATERIAL: 'UPDATE_SECTOR_WEDGE_MATERIAL',
      ADD_WEDGE_FASTENER: 'ADD_WEDGE_FASTENER',
      UPDATE_WEDGE_FASTENER: 'UPDATE_WEDGE_FASTENER'
    }),
    ...mapActions({
      addMultilayer: 'addMultilayer',
      replaceMaterialFromSystem: 'replaceMaterialFromSystem'
    }),
    selectProduct(product) {
      if (this.placement === 'system') {
        this.UPDATE_SECTOR_SYSTEM_LAYER_ITEM_MATERIAL({
          sectorIndex: this.sectorIndex,
          layerIndex: this.layerIndex,
          itemIndex: this.itemIndex,
          material: product.material
        })
        if (this.canReplaceMaterial) {
          const { sectorIndex, layerIndex, itemIndex } = this
          const productId = product.id
          const thickness = product?.material?.value

          this.replaceMaterialFromSystem({ sectorIndex, layerIndex, itemIndex, productId, thickness })
        }
      } else if (this.placement === 'junction') {
        this.UPDATE_JUNCTION_LAYER_ITEM_MATERIAL({
          sectorIndex: this.sectorIndex,
          junctionIndex: this.junctionIndex,
          layerIndex: this.layerIndex,
          itemIndex: this.itemIndex,
          material: product.material
        })
      } else if (this.placement === 'wedge') {
        const newPlates = cloneDeep(product.plates)
        newPlates.map(p => (p.count = 0))
        this.UPDATE_SECTOR_WEDGE_MATERIAL({
          sectorIndex: this.sectorIndex,
          slopeType: this.slopeType,
          id: product.id,
          plates: newPlates
        })
      } else if (this.placement === 'multilayer') {
        this.addMultilayer({
          sectorIndex: this.sectorIndex,
          layerIndex: this.layerIndex,
          itemIndex: this.itemIndex,
          material: product.material
        })
      } else {
        if (this.action === 'add') {
          this.ADD_WEDGE_FASTENER({
            sectorIndex: this.sectorIndex,
            id: product.id
          })
        } else {
          this.UPDATE_WEDGE_FASTENER({
            sectorIndex: this.sectorIndex,
            fastenerIndex: this.fastenerIndex,
            id: product.id
          })
        }
      }
      this.$emit('close')
    },
    isShowTooltip(product) {
      return (
        (Object.prototype.hasOwnProperty.call(product, 'text') ||
          Object.prototype.hasOwnProperty.call(product, 'url')) &&
        (product.text !== '' || product.url !== '')
      )
    }
  },
  computed: {
    ...mapState({
      sectors: state => state.sectors
    }),
    sectorIndex() {
      return this.$route.params.id ? this.$route.params.id : 0
    },
    junctionIndex() {
      return this.$route.params.number ? this.$route.params.number : 0
    },
    canReplaceMaterial() {
      const material = this.sectors[this.sectorIndex].layers[this.layerIndex]?.items
          .find((item, index) => index === this.itemIndex)

      return material ? material?.junctionProductReplace : false
    }
  }
}
</script>

<style scoped lang="sass">
.layers-products
  &__products
    display: grid
    +media((grid-template-columns: (320: repeat(2, 1fr), 576: repeat(3, 1fr), 960: repeat(4, 1fr))))
    max-height: calc(100vh - 256px)
    +media((max-width: (320: 92vw, 960: rem(960))))
    &-item
      border: rem(1) solid #ECECEC
      padding: rem(8)
      position: relative
      display: flex
      flex-direction: column
      justify-content: space-between
      &:after
        content: ""
        height: 100%
        width: 100%
        position: absolute
        left: 0
        top: 0
        background: rgba($red, .9)
        transition: .5s
        opacity: 0
        pointer-events: none
        background-blend-mode: normal, color
      .mobile-trigger
        background: transparent
        opacity: 0
        position: absolute
        left: 0
        top: 0
        width: 100%
        height: 100%
        z-index: 10
        +media((display: (320: block, 960: none)))
      .image
        position: relative
        height: 100%
        display: flex
        flex-direction: row
        justify-content: center
        align-items: center
        min-height: rem(120)
        background-size: cover
        background-position: center
      img
        max-width: 100%
        margin: 0 auto
        display: block
        height: auto
        max-height: rem(136)

      .name
        @extend .fs-14
        +media((font-size: (320: rem(10), 400: rem(12), 960: rem(14))))
        transition: .5s
        font-weight: bold
        z-index: 3
        position: relative
        padding: rem(8)
      .controls
        position: absolute
        top: 80%
        left: 50%
        transform: translate(-50%, -50%)
        z-index: 1
        opacity: 0
        pointer-events: none
        transition: cubic-bezier(0, 0, 0, 1.35) .3s
        display: flex
        flex-direction: row
        align-items: center
        width: 100%
        justify-content: center
        +media((display: (320: none, 960: flex)))
        &-info
          cursor: pointer
          line-height: 0
          margin-left: rem(4)
          transition: .5s
          height: rem(56)
          width: rem(40)
          background: rgba(246, 246, 246, 0.2)
          border-radius: rem(4)
          display: flex
          flex-direction: column
          justify-content: center
          align-items: center
          &:hover
            background: rgba(246, 246, 246, 0.5)
          input[type="radio"]
            position: absolute
            left: 0
            top: 0
            height: 0
            width: 0
            display: none
            opacity: 0
            pointer-events: none
        .simple-btn
          box-shadow: 0 rem(5) rem(20) rgba(51, 51, 51, 0.15), 0 rem(1) rem(4) rgba(51, 51, 51, 0.4)
          font-weight: 500
          padding: 0 rem(16)
      .info
        background: #FFFFFF
        box-shadow: 0 rem(4) rem(14) rgba(0, 0, 0, 0.16)
        border-radius: rem(4)
        +media((padding: (320: rem(17) rem(20), 992: rem(20) rem(20) rem(14))))
        display: flex
        flex-direction: column
        align-items: flex-start
        &__overlay
          z-index: 3
          +media((position: (320: fixed, 900: absolute)))
          +media((min-height: (320: 100vh, 900: 100%)))
          display: flex
          flex-direction: row
          justify-content: center
          align-items: center
          width: 100%
          top: 0
          left: 0
          transition: .5s
          +media((background: (320: rgba(0, 0, 0, 0.3), 900: transparent)))
          .info
            +media((min-height: (320: unset, 992: 100%)))
            +media((width: (320: rem(240), 992: 100%)))
            position: relative
      &:hover
        .name
          color: #fff
        .controls
          opacity: 1
          top: 50%
          pointer-events: auto
        &:after
          opacity: 1
.scrolling-container
  +media((max-width: (320: calc(100vw - 16px), 1020: rem(1020))))
  max-height: calc(100vh - 256px)
  height: 100%
  +media((margin-left: (320: rem(16), 960: calc((100% - 960px) / 2))))
</style>
